// Design ICU Variables
$brand-primary-hov:    darken($brand-primary, 10%) !default;
$brand-financials:     #3571ab;
$brand-financials-hov: darken($brand-financials, 10%) !default;
$brand-shares:         #7c40a3;
$brand-shares-hov:     darken($brand-shares, 10%) !default;;
$brand-diary:          #a33f45;
$brand-diary-hov:      darken($brand-diary, 10%) !default;;
$brand-rule26:         #29a39d;
$brand-rule26-hov:     darken($brand-rule26, 10%) !default;;
$brand-contact:        #b78e19;
$brand-contact-hov:    darken($brand-contact, 10%) !default;;

// Navigation Color Styles
ul#menu-primary li.menu-item-8 ul.dropdown-menu,
ul#menu-primary li.menu-item-8 ul.dropdown-menu a,
ul#menu-primary li.menu-item-8.current-menu-parent a,
ul#menu-primary li.menu-item-8.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-8.active a {
  background-color: $brand-primary;
  color: #fff;
}
ul#menu-primary li.menu-item-8 a:hover {
  background-color: $brand-primary-hov;
  color: #fff;
}
ul#menu-primary li.menu-item-922 ul.dropdown-menu,
ul#menu-primary li.menu-item-922 ul.dropdown-menu a,
ul#menu-primary li.menu-item-922.current-menu-parent a,
ul#menu-primary li.menu-item-922.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-922.active a {
  background-color: $brand-financials;
  color: #fff;
}
ul#menu-primary li.menu-item-922 a:hover {
  background-color: $brand-financials-hov;
  color: #fff;
}
ul#menu-primary li.menu-item-47 ul.dropdown-menu,
ul#menu-primary li.menu-item-47 ul.dropdown-menu a,
ul#menu-primary li.menu-item-47.current-menu-parent a,
ul#menu-primary li.menu-item-47.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-47.active a {
  background-color: $brand-shares;
  color: #fff;
}
ul#menu-primary li.menu-item-47 a:hover {
  background-color: $brand-shares-hov;
  color: #fff;
}
ul#menu-primary li.menu-item-46 ul.dropdown-menu,
ul#menu-primary li.menu-item-46 ul.dropdown-menu a,
ul#menu-primary li.menu-item-46.current-menu-parent a,
ul#menu-primary li.menu-item-46.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-46.active a {
  background-color: $brand-diary;
  color: #fff;
}
ul#menu-primary li.menu-item-46 a:hover {
  background-color: $brand-diary-hov;
  color: #fff;
}
ul#menu-primary li.menu-item-923 ul.dropdown-menu,
ul#menu-primary li.menu-item-923 ul.dropdown-menu a,
ul#menu-primary li.menu-item-923.current-menu-parent a,
ul#menu-primary li.menu-item-923.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-923.active a {
  background-color: $brand-rule26;
  color: #fff;
}
ul#menu-primary li.menu-item-923 a:hover {
  background-color: $brand-rule26-hov;
  color: #fff;
}
ul#menu-primary li.menu-item-42 ul.dropdown-menu,
ul#menu-primary li.menu-item-42 ul.dropdown-menu a,
ul#menu-primary li.menu-item-42.current-menu-parent a,
ul#menu-primary li.menu-item-42.current-menu-parent ul.dropdown-menu,
ul#menu-primary li.menu-item-42.active a {
  background-color: $brand-contact;
  color: #fff;
}
ul#menu-primary li.menu-item-42 a:hover {
  background-color: $brand-contact-hov;
  color: #fff;
}

// Page Layout
div.content {
  margin-top: 80px;
}

// Page Headers
div.page-header {
  margin-top: 0;
  margin-bottom: 40px;
}
div.page-header h1 {
  clear: both;
  text-transform: uppercase;
  color: #000;
  margin-top: 40px;
}
body.home .page-header {
  border: none;
}
body.home .page-header h1 {
  display: none;
}
body.single article header {
  border-bottom: 1px solid $gray-lighter;
  margin: 40px 0 40px 0;
}
body.single article header time,
body.single article header p {
  color: $gray-light;
  font-size: 12px;
}

// Headings
.main h1, .main h2, .main h3 {
  text-transform: uppercase;
}

// Tables
table {
  margin: 20px 0;
}
table td {
  border: 1px dotted $gray-lighter;
  padding: 10px
}

// Header Logo
header.navbar {
  border-bottom: 8px solid $brand-primary;
}
.main p.headerDescription {
  font-size: 12px;
  line-height: 1.2em;
  padding: 5px 0;
  margin: 0;
  color: $gray;
}
.main p.headerDescription a {
  color: $gray;
}
svg#abyLogo {
  padding: 0;
  margin: 0;
  width: 300px;
}

// Sidebar
.sidebar {
  color: $gray-lighter;
}
.sidebar h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}
.sidebar ul li {
  font-size: 14px;
}
.sidebar ul li a {
  color: $gray;
}

// Home Page Specials
body div.content {
  height: 1000px;
}
body.home hr {
  margin: 40px 0;
}
ul#homeNetwork {
  position: relative;
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul#homeNetwork li {
  padding: 0;
  margin: 0;
}
p.abyUpdated {
  position: relative;
  float: right;
  text-align: right;
  color: $gray-lighter;
  margin: 0;
  padding: 0;
}
body.home #carousel-abyHome {
  margin: 0 0 40px 0;
}
body.home div.carousel-caption {
  background-color: $brand-primary;
}
body.home div.carousel-caption h4 {
  font-size: 16px;
  font-weight: bold;
}

// Section Colorations
// financials
body.page-id-9 header.navbar,
body.parent-pageid-9 header.navbar {
  border-bottom: 8px solid $brand-financials;
}
body.page-id-9 a,
body.parent-pageid-9 a,
body.page-id-9 h2,
body.parent-pageid-9 h2,
body.page-id-9 h3,
body.parent-pageid-9 h3 {
  color: $brand-financials;
}
body.page-id-9 h2,
body.parent-pageid-9 h2 {
  border-bottom: solid 1px $brand-financials;
  padding: 20px 0 8px 0;
}
// shares
body.page-id-30 header.navbar,
body.parent-pageid-30 header.navbar {
  border-bottom: 8px solid $brand-shares;
}
body.page-id-30 a,
body.parent-pageid-30 a,
body.page-id-30 h2,
body.parent-pageid-30 h2,
body.page-id-30 h3,
body.parent-pageid-30 h3 {
  color: $brand-shares;
}
body.page-id-30 h2,
body.parent-pageid-30 h2 {
  border-bottom: solid 1px $brand-shares;
  padding: 20px 0 8px 0;
}
// diary and events
body.page-id-32 header.navbar,
body.parent-pageid-32 header.navbar {
  border-bottom: 8px solid $brand-diary;
}
body.page-id-32 a,
body.parent-pageid-32 a,
body.page-id-32 h2,
body.parent-pageid-32 h2,
body.page-id-32 h3,
body.parent-pageid-32 h3 {
  color: $brand-diary;
}
body.page-id-32 h2,
body.parent-pageid-32 h2 {
  border-bottom: solid 1px $brand-diary;
  padding: 20px 0 8px 0;
}
// rule 26 information
body.page-id-34 header.navbar,
body.parent-pageid-34 header.navbar {
  border-bottom: 8px solid $brand-rule26;
}
body.page-id-34 a,
body.parent-pageid-34 a,
body.page-id-34 h2,
body.parent-pageid-34 h2,
body.page-id-34 h3,
body.parent-pageid-34 h3 {
  color: $brand-rule26;
}
body.page-id-34 h2,
body.parent-pageid-34 h2 {
  border-bottom: solid 1px $brand-rule26;
  padding: 20px 0 8px 0;
}
// contact
body.page-id-40 header.navbar,
body.parent-pageid-40 header.navbar {
  border-bottom: 8px solid $brand-contact;
}
body.page-id-40 a,
body.parent-pageid-40 a,
body.page-id-40 h2,
body.parent-pageid-40 h2,
body.page-id-40 h3,
body.parent-pageid-40 h3 {
  color: $brand-contact;
}
body.page-id-40 h2,
body.parent-pageid-40 h2 {
  border-bottom: solid 1px $brand-contact;
  padding: 20px 0 8px 0;
}

// Contact Form 7
form.wpcf7-form input.wpcf7-text  {
	margin: 0;
	font-size: 12px;
	width: 100%;
	background-color: #f0f0f0;
	}
form.wpcf7-form textarea.wpcf7-textarea {
	margin: 0;
	font-weight: normal;
	font-size: 12px;
	width: 100%;
	background-color: #f0f0f0;
	}
form.wpcf7-form span.wpcf7-list-item {
	display: block;
	margin: 0;
	padding: 0;
	}

// Main Footer
footer.content-info {
	margin-top: 120px;
	padding: 60px 0;
	background-color: #f0f0f0;
	height: 200px;
	min-height: 200px;
}
footer.content-info p {
	font-size: 12px;
	line-height: 1.2em;
	color: $gray-light;
}
